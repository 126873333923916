import React from 'react';
import $ from 'jquery';


function parseButtonStyle(caption) {
  const styleRegex = /\[([^\]]+)\]/;
  const match = caption.match(styleRegex);

  let style = {
    backgroundColor: '#003874',
    color: '#ffffff',
    cursor: 'pointer'
  };

  let parsedCaption = caption;

  if (match) {
    const styleString = match[1];
    const styleProps = styleString.split(',');
    console.log(styleProps);

    styleProps.forEach(prop => {
      const [key, value] = prop.split(':').map(s => s.trim());
      if (key && value) {
        if (key === 'background') {
          style.backgroundColor = value;
        } else {
          style[key] = value;
        }
      }
    });

    parsedCaption = caption.replace(styleRegex, '').trim();
  }

  return { parsedCaption, style };
}
export const PopupService = {
  show: function (title, text, buttons, hide = true, isScrollText = false, fixBackgroundStyle = false, birthPopup = false) {

    const container = $('#popup-cotainer');
    container.find('.title').html(title);
    container.find('.text').html(text);


    if (isScrollText) {
      container.find('.generic-popup-container').addClass('scroll-generic-popup-container');
      container.find('.text').addClass('scroll-text');
    } else {
      container.find('.generic-popup-container').removeClass('scroll-generic-popup-container');
      container.find('.text').removeClass('scroll-text');
    }

    if (fixBackgroundStyle) {
      container.find('.generic-popup-container').addClass('remove-background');
      container.find('.buttons-container').addClass('remove-color');
      container.find('.text').addClass('image-background-size');
    } else {
      container.find('.generic-popup-container').removeClass('remove-background');
      container.find('.buttons-container').removeClass('remove-color');
      container.find('.text').removeClass('image-background-size');



    }

    if (birthPopup) {
      container.find('.generic-popup-container').addClass('remove-background-birth');
      container.find('.buttons-container').addClass('remove-color-birth');
      container.find('.text').addClass('image-background-birth-popup');
      container.find('.close-icon').addClass('show-close-button');

    } else {
      container.find('.generic-popup-container').removeClass('remove-background-birth');
      container.find('.buttons-container').removeClass('remove-color-birth');
      container.find('.text').removeClass('image-background-birth-popup');
      container.find('.close-icon').removeClass('show-close-button');
    }

    const buttonContainer = container.find('.buttons-container').empty();
    buttons.forEach((button, index) => {
      const { parsedCaption, style } = parseButtonStyle(button.caption);
      const buttonDom = $('<button class="' + (index === 0 ? 'confirm_btn' : 'secondary_btn') + '"></button>');
      buttonDom.html(parsedCaption);

      // Apply parsed styles
      buttonDom.css(style);

      if (hide) {
        buttonDom.click(PopupService.hide);
      }

      if (button.onClick) {
        if (button.disableFor) {
          let remainingTime = button.disableFor;
          buttonDom.prop('disabled', true);
          buttonDom.css('background-color', 'gray');
          buttonDom.css('cursor', 'no-drop');
          buttonDom.html(`${parsedCaption} <span>(${remainingTime})</span>`);

          const countdownInterval = setInterval(() => {
            remainingTime--;
            buttonDom.find('span').text(`(${remainingTime})`);

            if (remainingTime <= 0) {
              clearInterval(countdownInterval);
              buttonDom.prop('disabled', false);
              buttonDom.css(style);
              buttonDom.html(parsedCaption);
            }
          }, 1000);
        }
        buttonDom.click(button.onClick);
      }

      buttonContainer.append(buttonDom);
    });
    container.css('display', 'flex');
  },
  alert: function (title, text, onConfirm, confirmCaption) {
    PopupService.show(title, text, [
      {
        caption: confirmCaption || 'אישור',
        onClick: onConfirm
      }
    ]);
  },
  confirm: function (title, text, onConfirm) {
    PopupService.show(title, text, [
      {
        caption: 'אישור',
        onClick: onConfirm
      },
      {
        caption: 'ביטול'
      }
    ]);
  },
  hide: function () {
    $('#popup-cotainer').hide();
  }
};

export default class PopupBackground extends React.Component {
  countdownInterval = null;
  state = {
    secondaryButtonDisabled: false,
    secondaryButtonCaption: '',
    secondaryButtonCountdown: 0,
    secondaryButtonStyle: {
      backgroundColor: '#003874',
      color: '#ffffff',
      cursor: 'pointer'
    }
  };

  componentDidMount() {
    $(document).on('updateSecondaryButton', (_, data) => {
      const { parsedCaption, style } = parseButtonStyle(data.caption);
      this.setState({
        secondaryButtonDisabled: data.disabled,
        secondaryButtonCaption: parsedCaption,
        secondaryButtonCountdown: data.countdown,
        secondaryButtonStyle: style
      }, () => {
        if (this.state.secondaryButtonDisabled && this.state.secondaryButtonCountdown > 0) {
          this.startCountdown();
        }
      });
    });
  }

  componentWillUnmount() {
    $(document).off('updateSecondaryButton');
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  startCountdown = () => {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }

    this.countdownInterval = setInterval(() => {
      this.setState(prevState => ({
        secondaryButtonCountdown: prevState.secondaryButtonCountdown - 1
      }), () => {
        if (this.state.secondaryButtonCountdown <= 0) {
          clearInterval(this.countdownInterval);
          this.setState({ secondaryButtonDisabled: false });
        }
      });
    }, 1000);
  }

  render() {
    const { secondaryButtonDisabled, secondaryButtonCaption, secondaryButtonCountdown, secondaryButtonStyle } = this.state;

    const buttonStyle = {
      ...secondaryButtonStyle,
      backgroundColor: secondaryButtonDisabled ? 'gray' : secondaryButtonStyle.backgroundColor,
      cursor: secondaryButtonDisabled ? 'not-allowed' : 'pointer'
    };

    return (
      <div className="generic-popup-background" id="popup-cotainer">
        <div className={`generic-popup-container`}>
          <div onClick={() => PopupService.hide()} className="close-icon">
            <div className='vertical-line rotate' />
            <div className="horizontal-line" />
          </div>
          <div className="title"></div>
          <div className="text"></div>
          <div className="buttons-container">
            <button className="confirm_btn" style={buttonStyle}></button>
            <button
              className="secondary_btn"
              disabled={secondaryButtonDisabled}
              style={buttonStyle}
              dangerouslySetInnerHTML={{
                __html: secondaryButtonDisabled
                  ? `${secondaryButtonCaption} <span>(${secondaryButtonCountdown})</span>`
                  : secondaryButtonCaption
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
