
import React from "react";
import BasicTable from '../CustomTable'
import BaseComponent from "../BaseComponent";
import { ScanDocsApi } from "../../api/ScanLetteer";
//import { CustomerLetterApi, ILetters } from '../../api/CustomerLetter';
import { PopupService } from "../Macros/Popup";
import CustomSelect from "../Macros/CustomSelect";
import { UserDataService } from "../../services";


const headerColumns = [
  {
    header: 'מסמך'
  },
  {
    header: 'תאריך'
  }, {
    header: 'מספר חשבון'
  },
  {
    header: 'סוג חשבון'
  },
  {
    header: 'צפיה והורדה'
  },
  //  {
  //   header: 'הסרה'
  // }
];


const mobileHeaderColumns = [
  {
    header: 'מסמך'
  },
  {
    header: 'תאריך'
  }, {
    header: 'מספר חשבון'
  },
  {
    header: 'צפיה והורדה'
  },
]



export default class ScanLetter extends BaseComponent {


  constructor(props) {
    super(props);


    const accounts = UserDataService.data.accounts.map((x) => {
      return {
        label: x.id.toString(),
        value: x.id.toString(),
      };
    });
    accounts.splice(0, 0, { label: "כל החשבונות", value: "" });

    let years = this.siteSetting("reports_years_list").split(",");
    years.unshift("שנה");





    this.state = {
      docs: null,
      showDocs: null,
      showMobileDocs: null,
      isLoading: false,
      totalCoount: 0,
      unReaddocs: 0,

      selectedAccount: "",
      accounts: accounts,

      selectedYear: "",
      years: years.map(
        (x, i) =>
        ({
          label: x.toString(),
          value: i === 0 ? "" : x.toString(),
        })
      ),

      selectedType: "",
      types: [],
    }
  }

  componentDidMount() {
    this.fetchDocsData();
    this.getTypes();
  }


  getTypes() {
    ScanDocsApi.getTypes().then((res) => {
      if (res === null) {
        this.setState({ types: [] })
        return
      }

      const types = [
        { label: "כל הסוגים", value: "" },
        ...res.map(item => ({
          label: item.name,
          value: item.id.toString()
        }))
      ];

      this.setState({ types });

    }).catch((err) => {
      console.log(err);
    }
    );
  }

  getOptions() {

    var syb = this.siteSetting('lastyesrtoshow')
    var maxRevaluationDate = parseInt(sessionStorage.getItem("maxRevaluationDate"));
    var years = new Array();
    years.unshift("שנה");

    let i;
    const bottom = parseInt(syb);

    for (let year = maxRevaluationDate; year >= bottom; year--) {
      years.push(year);
    }

    // for (i = 0; i < parseInt(syb); i++) {
    //   years.push(parseInt(maxRevaluationDate) - i);
    // }
    return years.map(
      (x, i) =>
      ({
        label: x.toString(),
        value: i === 0 ? "" : x.toString(),
      })
    )

  }

  resetForm(event) {

    this.setState(
      {
        selectedType: "",
        selectedYear: "",
        selectedAccount: "",
      },
      this.fetchDocsData
    );

    event.preventDefault();
  }



  submitForm(event) {
    this.setState({},
      this.fetchDocsData);

    event.preventDefault();
  }


  fetchDocsData = (page) => {
    console.log("page", page);

    this.setState({
      isLoading: true,
    });
    ScanDocsApi.get({
      pageNo: page || 1,
      pageSize: 20,
      year: this.state.selectedYear ? parseInt(this.state.selectedYear) : null,
      accountId: this.state.selectedAccount ? parseInt(this.state.selectedAccount) : null,
      docType: this.state.selectedType ? parseInt(this.state.selectedType) : null
    })
      .then((res) => {
        console.log(this);
        if (res === null) {
          this.setState({
            isLoading: false,
            docs: [],
            showDocs: [],
            showMobileDocs: []
          })
        } else {


          const noResadNumbers = res?.docs.map(item => ({
            docTypeName: item.docTypeName,
            dateScan: item.dateScan,
            accountNumber: item.accountNumber,
            id: item.id,
            isViewed: item.isViewed
          })).filter(item => item.isViewed).map(item => item.id)
          sessionStorage.setItem('noReadBold', JSON.stringify(noResadNumbers))

          this.setState({
            isLoading: false,
            docs: res.docs,
            totalCoount: res.totalCount,
            unReaddocs: res.docs.filter(item => item.isViewed === false).length,
            showDocs: res.docs.map(item => ({
              docTypeName: item.docTypeName,
              dateScan: item.dateScan,
              accountNumber: item.accountNumber,
              fundName: item.fundName,
              id: item.id,
              isViewed: item.isViewed
            })),
            showMobileDocs: res.docs.map(item => ({
              docTypeName: item.docTypeName,
              dateScan: item.dateScan,
              accountNumber: item.accountNumber,
              id: item.id,
              isViewed: item.isViewed
            }))
          })

        }


      })
      .catch(() => {
        this.setState({
          isLoading: false,
          docs: null,
          showDocs: null,
          showMobileDocs: null
        })
        PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
      });
  }


  download(docId) {
    ScanDocsApi.Download(docId).then((res) => {
      if (res === null) {
        PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
        return
      }
      this.setState(prevState => ({
        unReaddocs: prevState.unReaddocs - 1
      }));
      // @ts-ignore
      if (window.navigator.msSaveOrOpenBlob) {
        const blobObject = new Blob([res], { type: 'application/pdf' });
        // @ts-ignore
        window.navigator.msSaveBlob(blobObject, 'report.pdf');
      } else {
        let file = new Blob([res], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        let link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        //  link.download = 'report.pdf';
        link.click();
      }
    }).catch((err) => {
      PopupService.alert('שגיאה', 'חלה תקלה, אנא נסו שנית מאוחר יותר', () => { });
    }
    );;
  }


  deleteAllLetters = () => {
    ScanDocsApi.deleteAll().then((res) => {
      if (res === true) { this.fetchDocsData() }
    })
      .catch((err) =>
        console.log(err)
      );
  }
  deleteDocs = (data) => {
    ScanDocsApi.delete(data).then((res) => {
      if (res === true) {
        this.fetchDocsData()
      }
    })

      .catch((err) =>
        console.log(err)
      );
  }

  handleDelete = (data) => {

    PopupService.show(
      'מכתבים שיוסרו לא יוצגו בדואר שלי, האם הינך רוצה להסיר את המכתבים',
      `<div id="mail-popup">
      </div>`,
      [
        {
          caption: this.label("mailing_popup_confirm_btn"),
          onClick: () => {

            this.deleteDocs(data.id);
            PopupService.hide();

          },
        },
        {
          caption: "לא מאשר",
          onClick: () => {

            PopupService.hide();

          },
        },
      ],
      false
    );

  }

  render() {
    console.log("unReaddocs", this.state.unReaddocs);

    return (
      <div className="customer-letter">
        {
          this.state.docs ?
            <>
              <div className="tax-reports-intro">
                <div className="content-wrapper">
                  <h1 className="tax-reports-intro__heading">
                    מסמכים סרוקים
                  </h1>
                </div>
              </div>

              <div className="content-wrapper content-wrapper--sheet">
                <div className="content-sheet">
                  <div className="reports-data">
                    <div className="pane-content pane-content--reports">


                      <div className="content-wrapper content-wrapper--sheet">

                        <section className="data-section">
                          <div className="filter-block-wrapper">
                            <div className="filter-block">
                              <h3 className="filter-block__heading">סנן לפי</h3>

                              <button
                                type="button"
                                data-toggle="collapse"
                                data-target="#collapseFilter"
                                aria-expanded="false"
                                aria-controls="collapseFilter"
                                aria-label="toggle controls"
                                className="filter-block__toggle collapsed"
                              >
                                <span className="caption">סנן לפי</span>
                              </button>

                              <div
                                id="collapseFilter"
                                className="collapse filter-block__collapse show"
                              >
                                <form
                                  id="reports-filter-form"
                                  className="data-filter filter-block__form"
                                >
                                  <div className="data-filter__row">
                                    <div className="data-filter__inputs">
                                      <div className="data-filter__inputs-row">
                                        <div className="col-auto data-filter__col">
                                          <label htmlFor="df-type" className="sr-only">
                                            כל הסוגים
                                          </label>
                                          <CustomSelect
                                            id="df-type"
                                            buttonClassName="data-filter__input"
                                            options={this.state.types}
                                            value={this.state.selectedType}
                                            onChange={(value) => {
                                              this.setState({ selectedType: value });
                                            }}
                                          />
                                        </div>
                                        <div className="col-auto data-filter__col">
                                          <label htmlFor="df-date" className="sr-only">
                                            מתאריך
                                          </label>
                                          <CustomSelect
                                            id="df-date"
                                            buttonClassName="data-filter__input"
                                            options={this.getOptions()}
                                            value={this.state.selectedYear}
                                            onChange={(value) => {
                                              this.setState({ selectedYear: value });
                                            }}
                                          />
                                        </div>
                                        <div className={`col-auto data-filter__col ${this.state.selectedType === "60" ? "disabled" : ""}`}>
                                          <label
                                            htmlFor="df-accounts"
                                            className="sr-only"
                                          >
                                            כל החשבונות
                                          </label>
                                          <CustomSelect
                                            id="df-accounts"
                                            buttonClassName="data-filter__input"
                                            options={this.state.accounts}
                                            value={this.state.selectedAccount}
                                            onChange={(value) => {
                                              this.setState({ selectedAccount: value });
                                            }}
                                            disabled={this.state.selectedType === "60"}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="data-filter__buttons">
                                      <div className="data-filter__buttons-row">
                                        <div className="col-auto data-filter__buttons-col">
                                          <button
                                            type="button"
                                            className="data-filter__btn data-filter__btn--reset"
                                            onClick={this.resetForm.bind(this)}
                                          >
                                            נקה
                                          </button>
                                        </div>
                                        <div className="col-auto data-filter__buttons-col">
                                          <button
                                            type="button"
                                            className="data-filter__btn"
                                            onClick={this.submitForm.bind(this)}
                                          >
                                            הצג
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </section>


                      </div>

                      <div className="desc-view">
                        <BasicTable
                          headerColumns={headerColumns}
                          data={this.state.showDocs}
                          downloadPdf={(id) =>
                            this.download(id)
                          }
                          onDelete={this.handleDelete}
                          paging={this.fetchDocsData}
                          totalCount={Math.ceil(this.state.totalCoount / 20)}
                          isRplaceIcons

                        />
                      </div>
                      <div className="mobile-view">
                        <BasicTable

                          headerColumns={mobileHeaderColumns}
                          data={this.state.showMobileDocs}
                          downloadPdf={(id) =>
                            this.download(id)
                          }
                          onDelete={this.handleDelete}
                          paging={this.fetchDocsData}
                          totalCount={Math.ceil(this.state.totalCoount / 20)}
                          isRplaceIcons
                        />
                      </div>



                      <style>
                        {" "}
                        {
                          "@media (max-width:991px) { .desc-view{display:none !important} }"
                        }{" "}
                      </style>
                      <style>
                        {" "}
                        {
                          "@media (min-width:991px) { .mobile-view{display:none !important} }"
                        }{" "}
                      </style>



                    </div>
                  </div>
                </div>
              </div>
            </>


            :
            <div style={{ textAlign: "center", paddingTop: "2em" }}>
              <img src="/img/loading.gif" alt="loading" />
            </div>


        }


      </div>
    );
  }

}
